@import 'reset';
@import 'includes';

* {
  box-sizing: border-box;
}

.new-line {
  display: block;
}

.inline {
  display: inline;
}

.kyc-text-align-center {
  text-align: center;
}

.kyc-text-align-left {
  text-align: left;
}

.kyc-text-align-right {
  text-align: right;
}

.kyc-clickable {
  cursor: pointer;
}

@media screen and (max-width: px-to-rem($small)) {
  .specific.kyc-hide-on-small {
    display: none;
  }
}

.kyc-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: px-to-rem($increased)) {
    flex-direction: column-reverse;
    gap: px-to-rem(16);

    button {
      width: 100%;
    }
  }
}

.kyc-card-disabled {
  cursor: default;
  opacity: 0.3;
}

.kyc-font-weight-600 {
  font-weight: 600;
}

.kyc-margin-top-8 {
  margin-top: 8rem;
}

.kyc-margin-bottom-4 {
  margin-bottom: 4rem;
}

.kyc-align-content-center {
  align-content: center;
}

.pep-form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: px-to-rem(32);

  kyc-screen-element-form-control:empty {
    display: none;
  }

  .pep-radio-buttons {
    display: flex;
    flex-direction: row;
    gap: px-to-rem(32);
  }

  .pep-fit-row {
    grid-column: 1 / span 2;
  }

  @media screen and (max-width: px-to-rem($increased)) {
    display: block;

    .ps-form-field {
      margin-bottom: px-to-rem(16);
    }

    .pep-radio-buttons {
      display: flex;
      flex-direction: column;
      gap: px-to-rem(12);
      margin-bottom: px-to-rem(16);
    }

    .section-title {
      margin-bottom: px-to-rem(16);
    }
  }
}
